import React from "react";
import { setQueryStringValue } from "../queryString";
import configData from "../config.json";

const ProductsFilter = (props) => {
  const {
    location,
    filterTags,
    filterLevel1Selected,
    filterLevel2Selected,
    setFilterLevel1Selected,
    setFilterLevel2Selected,
    setListCount,
  } = props;

  const handleFilter1Change = (cat) => {
    setFilterLevel1Selected(cat);
    setFilterLevel2Selected([]);
    setListCount(configData.PRODUCTS_COUNT_INIT);
    setQueryStringValue(location, "cat", cat);
    setQueryStringValue(location, "tag", "");
  };

  const handleFilter2Change = (tag) => {
    let selected;
    if (filterLevel2Selected.includes(tag)) {
      selected = filterLevel2Selected.filter((x) => x !== tag);
    } else {
      selected = [...filterLevel2Selected, tag];
    }

    setFilterLevel2Selected(selected);
    setQueryStringValue(location, "tag", selected.join(","));
  };
  //console.log(location, location.search, "filter..");

  const filterLevel1 = Object.values(filterTags);
  const filterLevel2 = filterTags[filterLevel1Selected]
    ? filterTags[filterLevel1Selected].subCat
    : [];

  //console.log(filterLevel1Selected, "filterLevel1Selected..");
  return (
    <>
      <form style={{ width: "100%" }}>
        <select
          className="uk-select"
          value={filterLevel1Selected || "default"}
          onChange={(e) => handleFilter1Change(e.target.value)}
        >
          {filterLevel1Selected ? null : (
            <option value="default">Filter By:</option>
          )}
          {filterLevel1.map((opt) => {
            return (
              <option value={opt.slug} key={opt.slug}>
                {filterLevel1Selected === opt.slug
                  ? `Filter By: ${opt.name}`
                  : opt.name}
              </option>
            );
          })}
        </select>
        <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid uk-margin-top">
          {filterLevel2.map((t) => {
            return (
              <label key={t.slug}>
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  id={t.slug}
                  value={t.slug}
                  checked={filterLevel2Selected.includes(t.slug)}
                  onChange={() => handleFilter2Change(t.slug)}
                />
                {` ${t.name}`}
              </label>
            );
          })}
        </div>
      </form>
    </>
  );
};

export default ProductsFilter;
