import React, { useState } from "react";
import ProductsFilter from "./productsFilter";
import { setQueryStringValue } from "../queryString";
import configData from "../config.json";

const ProductsToolsBar = (props) => {
  const {
    location,
    filterLevel1Selected,
    setFilterLevel1Selected,
    setFilterLevel2Selected,
    setListCount,
    setListSortBy,
  } = props;

  const [open, setOpen] = useState(false);
  const [sortBySelectedIndex, setSortBySelectedIndex] = useState(0);

  const handleClearFilters = () => {
    setFilterLevel1Selected("");
    setFilterLevel2Selected([]);
    setListCount(configData.PRODUCTS_COUNT_INIT);
    setQueryStringValue(location, "cat", "");
    setQueryStringValue(location, "tag", "");
    setOpen(false);
  };

  const handleSetListSortBy = (e) => {
    const sortby = e.target.value;
    setListSortBy(sortby);

    setSortBySelectedIndex(e.target.selectedIndex);
  };

  const filterLabel = open ? "Hide Filters" : "Filters";
  const sortByOptions = [
    { value: "default", text: "Newest" },
    { value: "name", text: "Product Name" },
    { value: "thumbup", text: "Thumb Up" },
    { value: "recommended", text: "Recommended" },
  ];

  return (
    <div className="uk-margin-medium-bottom">
      <div
        className="tool-bar-controller"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
          gridGap: "20px",
        }}
      >
        <form>
          <select
            className="uk-select"
            defaultValue="default"
            onChange={(e) => handleSetListSortBy(e)}
          >
            {sortByOptions.map((opt, idx) => {
              return (
                <option value={opt.value} key={opt.value}>
                  {sortBySelectedIndex === idx
                    ? `Sort By: ${opt.text}`
                    : opt.text}
                </option>
              );
            })}
          </select>
        </form>

        <button
          uk-toggle="target: #filters"
          type="button"
          className="uk-button uk-button-default"
          onClick={() => setOpen(!open)}
        >
          {filterLabel}
        </button>
        {filterLevel1Selected ? (
          <button
            className="uk-button uk-button-default"
            onClick={() => handleClearFilters()}
          >
            Clear Filters
          </button>
        ) : null}
      </div>

      <div
        id="filters"
        className="uk-margin-top filters"
        hidden={open ? "" : "hidden"}
      >
        <ProductsFilter {...props} />
      </div>
    </div>
  );
};

export default ProductsToolsBar;
