import queryString from "query-string";

const setQueryStringWithoutPageReload = (location, qsValue) => {
  const newurl = `${location.protocol}//${location.host}${location.pathname}${qsValue}`;
  window.history.pushState({ path: newurl }, "", newurl);
};

export const setQueryStringValue = (location, key, value) => {
  const query = location.search;
  const values = queryString.parse(query);
  const newQsValue = queryString.stringify({ ...values, [key]: value });
  location.search = `?${newQsValue}`;
  setQueryStringWithoutPageReload(location, `?${newQsValue}`);
};

export const getQueryStringValue = (location, key) => {
  const query = location.search;
  const values = queryString.parse(query);
  return values[key];
};
