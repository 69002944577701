import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout";
import ProductsToolsBar from "../../components/productsToolsBar";
import Product from "../../components/product";
import configData from "../../config.json";
import { getQueryStringValue } from "../../queryString";
import "../../assets/css/main.css";
import "./index.css";

const IndexPage = ({ location }) => {
  //console.log(location, "pord....");
  const data = useStaticQuery(query);
  const products = data.allStrapiProduct.edges;

  const [listCount, setListCount] = useState(configData.PRODUCTS_COUNT_INIT);
  const [listSortBy, setListSortBy] = useState("default");
  const [filterTags, setFilterTags] = useState({});
  const [filterLevel1Selected, setFilterLevel1Selected] = useState(
    getQueryStringValue(location, "cat") || ""
  );
  const [filterLevel2Selected, setFilterLevel2Selected] = useState(
    getQueryStringValue(location, "tag")
      ? getQueryStringValue(location, "tag").split(",")
      : []
  );

  useEffect(() => {
    const filterTags = {};
    if (products) {
      for (let prod of products) {
        if (!filterTags[prod.node.category.slug])
          filterTags[prod.node.category.slug] = {
            slug: prod.node.category.slug,
            name: prod.node.category.name,
            subCat: [...prod.node.sub_categories],
          };
        else {
          let tmp = filterTags[prod.node.category.slug].subCat.map(
            (x) => x.slug
          );
          prod.node.sub_categories.forEach((x) => {
            if (!tmp.includes(x.slug))
              filterTags[prod.node.category.slug].subCat.push(x);
          });
        }
      }
      setFilterTags(filterTags);
    }
  }, [products]);

  const handleLoadMore = () => {
    setListCount((prev) => {
      return Math.min(
        prev + configData.PRODUCTS_COUNT_ADDMORE,
        products.length
      );
    });
  };

  let filterList;
  if (!filterLevel1Selected) filterList = [...products];
  else {
    filterList = products.filter(
      (item) => item.node.category.slug === filterLevel1Selected
    );
    if (filterLevel2Selected && filterLevel2Selected.length) {
      filterList = filterList.filter((item) => {
        for (let x of item.node.sub_categories) {
          if (filterLevel2Selected.includes(x.slug)) return true;
        }
        return false;
      });
    }
  }

  if (listSortBy === "default") {
    filterList.sort((a, b) => b.node.strapiId - a.node.strapiId);
  } else if (listSortBy === "name") {
    filterList.sort((a, b) => {
      if (a === b) return 0;
      return a.node.name < b.node.name ? -1 : 1;
    });
  } else if (listSortBy === "recommended") {
    filterList.sort((a, b) => b.node.recommended - a.node.recommended);
  } else if (listSortBy === "thumbup") {
    filterList.sort((a, b) => b.node.upCount - a.node.upCount);
  }

  const loadMoreButton =
    filterList.length > listCount ? (
      <button
        type="button"
        className="uk-button uk-button-secondary uk-button-large uk-text-nowrap uk-width-1-1 uk-width-1-3@s uk-margin-large-top"
        onClick={handleLoadMore}
      >
        Load More
      </button>
    ) : (
      ""
    );

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <div className="uk-section">
        <div className="uk-container uk-container-large">
          <ProductsToolsBar
            location={location}
            filterTags={filterTags}
            filterLevel1Selected={filterLevel1Selected}
            filterLevel2Selected={filterLevel2Selected}
            listCount={listCount}
            listSortBy={listSortBy}
            setFilterTags={setFilterTags}
            setFilterLevel1Selected={setFilterLevel1Selected}
            setFilterLevel2Selected={setFilterLevel2Selected}
            setListCount={setListCount}
            setListSortBy={setListSortBy}
          />
          <div className="products-list-container">
            {filterList.slice(0, listCount).map((x) => {
              return <Product product={x} key={x.node.strapiId} />;
            })}
          </div>
          <div className="uk-text-center">{loadMoreButton}</div>
        </div>
      </div>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiProduct {
      edges {
        node {
          strapiId
          name
          isAmazonProduct
          imgSrc
          affiLink
          isAffiLinkDead
          features
          recommended
          upCount
          category {
            name
            slug
          }
          sub_categories {
            name
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;
