import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import UpDownVote from "./upDownVote";
import AffiliateLink from "./affiliateLink";
import "../assets/css/product.css";

const Product = ({ product }) => {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  const recommendedIcon = product.node.recommended ? (
    <FontAwesomeIcon icon={faStar} />
  ) : (
    ""
  );

  return (
    <div
      className="uk-card uk-card-default"
      key={isClient ? "client" : "server"}
    >
      <div className="uk-card-media-top card-img-container">
        <AffiliateLink product={product.node}>
          <img
            src={
              product.node.isAmazonProduct
                ? `https://m.media-amazon.com/images/I/${product.node.imgSrc}._SL800_.jpg`
                : product.node.imgSrc
            }
            alt={product.node.name}
          />
        </AffiliateLink>
        <span>{recommendedIcon}</span>
      </div>
      <div className="uk-card-body">
        <h3 className="uk-card-title">
          <AffiliateLink product={product.node} className="uk-link-heading">
            {product.node.name}
          </AffiliateLink>
        </h3>
        <ul className="product-features">
          {product.node.features.map((f, index) => {
            return (
              <li key={index}>
                <span>{f}</span>
              </li>
            );
          })}
        </ul>
      </div>
      <div
        className="uk-card-footer"
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 3fr",
          alignItems: "center",
        }}
      >
        <UpDownVote id={product.node.strapiId} isClient={isClient} />
        <AffiliateLink
          product={product.node}
          className="uk-button uk-button-primary uk-text-nowrap"
        >
          Check Price
        </AffiliateLink>
      </div>
    </div>
  );
};

export default Product;
